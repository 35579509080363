import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
//加载
const routes = [
  {
    path: '',
    redirect: "/home",
  },
  {
    path: '/home',
    name:'home',
    component: () => import('@/pages/home/index.vue'),
  },
  {
    path: '/artist',
    name:'artist',
    component: () => import('@/pages/artist/index.vue'),
  },
  {
    path: '/news',
    name:'news',
    component: () => import('@/pages/news/index.vue'),
  },
  {
    path: '/news-detail/:id',
    name:'news-detail',
    component: () => import('@/pages/news-detail/index.vue'),
  },
  {
    path: '/projects',
    name:'projects',
    component: () => import('@/pages/perform/index.vue'),
  },
  {
    path: '/projects-detail/:id',
    name:'projects-detail',
    component: () => import('@/pages/perform-detail/index.vue'),
  },
  {
    path: '/contact',
    name:'contact',
    component: () => import('@/pages/contact/index.vue'),
  },
  {
    path: '/about',
    name:'about',
    component: () => import('@/pages/about/index.vue'),
  },
]
const router = new VueRouter({
    mode: 'history', 
    linkActiveClass:'router-active',
    routes,
})
router.beforeEach((to, from, next) => {
    // Scroll to top before navigating to the new route
    let arr=["news-detail","projects-detail"]
    if(arr.includes(to.name)){
        document.getElementById('app').scrollTo(0, 0);
    }
    next();
});
router.afterEach((to,from)=>{
    let lang = window.localStorage.getItem("lang");
    if(!lang){
        lang="CHN"
    }
    if(lang==='CHN'){
        window.location.hash='zh'
    }else{
        window.location.hash='en'
    }
})


export default router;