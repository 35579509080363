export default  {
    CHN:"中文",
    ENG:"英文",
    home:"首页",
    artist:"艺术家",
    news:"新闻",
    about:"关于极致",
    projects:"项目",
    contact:"联系方式",
    contact_email:"联系方式",

    language:'语言',
    confirm:"确认",
    cancel:"取消",

    news_detail:"新闻详情",
    projects_name:"项目详情",
    time:"发布时间",
    related_artists:"相关艺术家",
    'All Artists':"全部艺术家",
    trade:"行业",
    album:"图集",
    more:"更 多 新 闻",

    image_view:"图文列表",
    list_view:"文字列表",
    up_coming:"即 将 呈 现",
    past:"经 典 案 例",
    about_us:"关于极致音乐",

    pec:"请输入内容",
    ps:"请选择",
    phone:"电话",
    address:"地址",
    email:"邮箱",
    map_navigation:"地图导航",

    Jan:'1月',
    Feb:'2月',
    Mar:'3月',
    Apr:'4月',
    May:'5月',
    Jun:'6月',
    Jul:'7月',
    Aug:'8月',
    Spt:'9月',
    Oct:'10月',
    Nov:'11月',
    Dec:'12月',
}