import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    info: {},
    artists:[],
    categories:[],
  },
  getters: {
    GET_ARTISTS_SORT: state => {
        return state.info.config?.artistsSort ?? []
    },
    GET_CATEGORIES_SORT: state => {
        return state.info.config?.categoriesSort ?? []
    },
    GET_NEWS_SORT: state => {
        return state.info.config?.newsSort ?? {}
    },
    GET_PERFORMS_SORT: state => {
        return state.info.config?.performsSort ?? {}
    },
    GET_CONTACTS_SORT: state => {
        return state.info.config?.contactsSort ?? []
    },
    GET_ARTISTS: state => {
        return state.artists ?? []
    },
    GET_CATEGORIES: state => {
        return state.categories ?? []
    },
    GET_SYSTEM_SET: state => {
        return state.info?.content ?? {}
    },

    GET_LANG: state => {
      return state.info.config?.lang_cfg ?? [];
    },
    GET_BIOGRAPHY_BANNER: state => {
      return state.info.biography_banner_pic;
    },
    GET_HOME_PIC: state => {
      return state.info.home_pic;
    },
    GET_HOME_MOBILE_PIC: (state)=> {
      if(Array.isArray(state.info.config?.mobile_home_pic)){
        if(state.info.config?.mobile_home_pic[0]){
          return state.info.config?.mobile_home_pic[0]
        }else{
          return state.info.home_pic
        }
        
      }else{
        return state.info.config?.mobile_home_pic
      }
    },
    
  },
  mutations: {
    SET_INFO (state,info) {
      state.info=info
    },
    SET_ARTISTS (state,info) {
        state.artists=info
    },
    SET_CATEGORIES (state,info) {
        state.categories=info
    }
  }
})

export default store
