import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router'
import VueI18n from 'vue-i18n';

import Vant from 'vant';
import 'vant/lib/index.css';
import '@vant/touch-emulator';
Vue.use(Vant);

import en from '@/assets/lang/en'
import zh from '@/assets/lang/zh'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import locale from 'element-ui/lib/locale'


Vue.use(VueI18n);
Vue.use(ElementUI);

import AppNav from "@/components/Nav"
import Bottom from "@/components/Bottom"
Vue.component('AppNav', AppNav)
Vue.component('Bottom', Bottom)
const messages = {
    ENG: {
        ...en,
        ...enLocale
    },
    CHN: {
        ...zh,
        ...zhLocale
    },
   
}

import store from './store'

const i18n = new VueI18n({
    locale: 'CHN',
    messages,
});
locale.i18n((key, value) => {
    let data=i18n.t(key, value);
   return data
})

Vue.config.productionTip = false
Vue.prototype.$global={isMobile:false,systemInfo:{}}


new Vue({
    render: h => h(App),
    i18n,
    router,
    store: store,

}).$mount('#app')