export default {
    CHN:"CHN",
    ENG:"ENG",
    home:"Home",
    artist:"Artists",
    news:"News",
    about:"About",
    projects:"Projects",
    contact:"Contact",
    contact_email:"Contact Us",
    language:'language',
    confirm:"confirm",
    cancel:"cancel",
    news_detail:"News Detail",
    projects_name:"Projects Name",
    time:"time",
    related_artists:"Related Artists",
    'All Artists':"All Artists",
    trade:"trade",
    album:"album",
    more:"More News",


    image_view:"Image View",
    list_view:"List View",
    up_coming:"Upcoming",
    past:"Past",
    about_us:"About Us",

    pec:"Please enter content",
    ps:"Please select",
    phone:"phone",
    address:"Address",
    email:"E-mail",
    map_navigation:"Map navigation",

    Jan:'Jan',
    Feb:'Feb',
    Mar:'Mar',
    Apr:'Apr',
    May:'May',
    Jun:'Jun',
    Jul:'Jul',
    Aug:'Aug',
    Spt:'Spt',
    Oct:'Oct',
    Nov:'Nov',
    Dec:'Dec',
}