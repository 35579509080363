<template>
    <div class="bottom">
       
        <div class="bottom-copyright">
            <span>© 2021 极致音乐版权所有</span> | <span>粤ICP备88888888号</span>
        </div>
        <img class="bottom-logo" src="@/assets/images/logo.png">
    </div>
</template>

<script>
export default {

    computed: {
        info() {
            let data = this.$store.getters['GET_SYSTEM_SET']
            if (typeof data === 'string') {
                data = JSON.parse(data)
            }
            return data
        }
    }
}
</script>

<style lang="less" scoped>
.bottom {
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 181px;
    background: url(~@/assets/images/bottom_bg.png) no-repeat;
    background-size: 1998px 200px;
    background-position: center;

    .bottom-contact {
        margin-left: 61px;
        display: flex;
        margin-bottom: 20px;

        .bottom-contact__item {
            margin-right: 40px;

            .bci-title {
                font-size: 16px;
                color: #fff;
            }

            .bci-name {
                font-size: 16px;
                color: #fff;
            }
        }
    }

    .bottom-copyright {
        margin-left: 61px;

        span {
            color: rgba(255, 255, 255, .6);
            font-size: 18px;

        }
    }

    .bottom-logo {
        width: 192px;
        height: 70px;
        position: absolute;
        right: 100px;
        top: 50%;
        transform: translateY(-50%);

    }

}

@media screen and (max-width: 767px) {
    .bottom {
        background: #2b3041;
        height: 20vw;
    }

    .bottom-logo {
        width: 20vw !important;
        height: auto !important;
        right: 4vw !important;
    }

    .bottom-contact {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 2vw 0;
    }

    .bottom-copyright {
        span {
            font-size: 3vw !important;
        }
    }
}</style>