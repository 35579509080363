<template>
    <div :class="{ 'nav_home': isHome, 'nav': true }">
        <img class="nav_logo" src="@/assets/images/logo.png" alt="">
        <div class="nav_right"  >
            <div class="nav_list" :aaa="$route.name">
            <router-link :to="'/' + item" v-for="item in navList" :key="item"
            :class="[($route.name=='news-detail' && item==='news') || ($route.name=='projects-detail' && item==='projects')?'router-active':'','nav_list_item']" >{{ $t(item)}}</router-link>
        </div>
        <div class="nav_language"  v-if="!isHome" >
            <template  v-for="item in ['CHN','ENG']"  >
                <div :class="{ 'current': $i18n.locale == item }" :key="item"  :style="{color:type=='black'?'#2C2C2C':'#fff'}" @click="changeLang(item)">
                    <!-- <span>{{ $t(item) }}</span>
                    <span v-if="$i18n.locale!=='CHN'">{{ item==='CHN'?'中文':'英文'}}</span>
                    <span v-else>{{ item }}</span> -->

                    <span>{{ item=='CHN'?'中文':'ENG' }}</span>
                    <span >{{item=='CHN'?'CHN':'英文' }}</span>

                </div>
            </template>
        </div>
        </div>



        <!-- 移动端适配顶部 -->
        <div class="m_nav">
            <van-row>
                <van-col span="2" offset="18" @click="popup = true"> <van-icon :name="require('@/assets/images/nav_icon_1.png')" size="20" /></van-col>
                <van-col span="4" @click="langPopup=true"> <van-icon :name="require('@/assets/images/nav_icon_2.png')" size="20" /></van-col>
            </van-row>
        </div>
        <van-popup class="m_nav_popup" v-model="popup" position="right" style="height:100%">
            <van-sidebar v-model="routeIndex">
                <van-sidebar-item :title="$t(item)" v-for="item in navList" :key="item" @click="changeNav(item)" />
            </van-sidebar>
        </van-popup>
        <van-popup v-model="langPopup" position="bottom" style="height:40%">
            <van-picker :title="$t('language')" :confirm-button-text="$t('confirm')" :cancel-button-text="$t('cancel')" show-toolbar :columns="['中文','ENG']" :default-index="langIndex" @confirm="onConfirm" @cancel="langPopup=false" />
        </van-popup>
    </div>
</template>

<script>
export default {
    name: "AppNav",
    props: {
        type: {
            type: String,
            default: "white",
        },
        isHome: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            popup: false,
            langPopup:false,
            navList:['home','artist','news','projects','about','contact'],
        }
    },
    computed: {
        langs(){
            let lang=this.$store.getters['GET_LANG']
            let sort=['CHN','ENG','GER']
            const list = lang.sort((a, b) => sort.indexOf(a) - sort.indexOf(b));
            return list
        },
        routeIndex: {
            get() {
                // console.log(this.navList)
                let path = this.$route.fullPath.replace('/', '')
                let index = this.navList.findIndex(item => item === path)
                return index;
            },
            set() {

            },
        },
        mLangs(){
            let list=this.$store.getters['GET_LANG']
            let data=list.map(item=>{
                return  {text:this.$t(item)}
            })
            return data
        },
        langIndex(){
            let index;
            try {
                index=this.langs.findIndex(item=>item===this.$i18n.locale)
            } catch (error) {
                index=1;
            }
            return index
        }
    },
    methods: {
        goHome() {
            this.$router.push('/home')
        },
        onConfirm(v,i){
            this.langPopup=false
            setTimeout(() => {
                this.changeLang(v)
            }, 200);
        },
        changeLang(lang='ENG') {
            console.log('aaa')
            if(lang==='中文'){
                lang='CHN'
            }
            this.$i18n.locale = lang
            window.localStorage.setItem('lang', lang)
            if(lang==='CHN'){
                window.location.hash='zh'
            }else{
                window.location.hash='en'
            }
            
        },
        changeNav(item) {
            this.popup = false;
            setTimeout(() => {
                this.$router.push('/' + item)
            }, 100);
        },
    },
    mounted(){
    },
}
</script>

<style lang="less">
.nav {
    position: absolute;
    z-index: 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background: rgba(0, 0, 0, 0.1);
    .nav_logo{
        margin-left: 115px;
        margin-right: 113px;
        width: 132px;
        height: 48px;
    }
    .nav_right{
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-right: 200px;
        .nav_list {
            height: 100%;
            display: flex;
            
            .nav_list_item {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                margin-right: 40px;
                font-size: 24px;
                transition: all 0.8s;
                box-sizing: border-box;
                color: rgba(255, 255, 255, 0.6);
                &::after {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    display: inline-block;
                    left: 50%;
                    bottom: -18px;
                    transform: translateX(-50%);
                    width: 0%;
                    height: 2px;
                    background: transparent;
                    transition: all 0.8s;
                }

                &.router-active {
                    position: relative;
                    color:#fff;
                    &::after {
                        position: absolute;
                        left: 50%;
                        bottom: -18px;
                        transform: translateX(-50%);
                        width: 28px;
                        height: 4px;
                        background: url(~@/assets/images/check_icon.png) no-repeat;
                        background-size: cover;
                        transition: all 0.8s;
                    } 
                  
                }
            }
            .nav_list_item_black{
                color: #2C2C2C ;
            }
        }

        .nav_language {
            display: flex;
            &>div {
                position: relative;
                display: flex;
                margin-left: 40px;
                font-size: 18px;
                transition: all 0.8s;
                cursor: pointer;
                color: #fff;
                &.current {
                    color: #FA6400 !important;
                }
                
                span{
                    line-height: 1;
                    &:nth-child(1){}
                    &:nth-child(2){
                        position: absolute;
                        left:50%;
                        top:100%;
                        transform: translateX(-50%) scale(0.65);
                        font-size: 12px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.nav_home {
    justify-content: end !important;
    .nav_list {
        .nav_list_item {
            align-items: end !important;
            padding-bottom: 12px;
            margin-right: 0;
            margin-left: 48px;
            font-family: ONEAN_REGULAR;
        }
    }

    &::after {
        display: none;
    }
}

.m_nav {
    display: none;
}

@media screen and (max-width: 767px) {
    .nav {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        padding: 0;
        width: 100%;
        height: 13.3333vw;
        flex: none;
        border: none;
        background: rgba(0, 0, 0, 0.1);

       &::after {
            display: none;
        }
        
        .nav_name,
        .nav_list,
        .nav_right,
        .nav_language {
            display: none;
        }
        .nav_logo{
 
            width: auto;
            height: 60%;
        }
        .nav_name_first,
        .nav_name_last {
            color: #fff;
            font-size: 5vw;
            font-weight: 700;
            margin-right: 10px;
            padding-top: 0.5vw;
        }
        .nav_name_last {
            color: #FA6400;
        }
        .m_nav {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            .van-row {
                width: 100%;

                .van-col {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .m_nav_popup {
            width: 120px;

            .van-sidebar {
                width: 100%;
            }
        }
    }
}

</style>