<template>
    <div id="app">
        <transition name="fade">
            <keep-alive>
                <router-view :key="key" />
            </keep-alive>
        </transition>

    </div>
</template>

<script>
import { getInfo } from "@/api/index";
import { getArtists, getCategories } from "@/api/index";
export default {
    name: "App",
    data() {
        return {
            showNav: true,
            ready: false,
        };
    },
    computed: {
        key() {
            return this.$route.name;
        },
    },
    methods: {
        init() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            if (flag) {
                //如果flag不为空，说明可以匹配到，是手机端
                this.$global.isMobile = true;
            }
                this.initLang()

        },
        async initLang() {
            if(window.location?.search?.includes('qr_en')){
                this.$i18n.locale =  "ENG";
                window.localStorage.setItem("lang", this.$i18n.locale);
                return;
            }
            if(window.location?.hash?.includes('zh')){
                this.$i18n.locale =  "CHN";
                window.localStorage.setItem("lang", this.$i18n.locale);
                return;
            }
            if(window.location?.hash?.includes('en')){
                this.$i18n.locale =  "ENG";
                window.localStorage.setItem("lang", this.$i18n.locale);
                return;
            }
            
            // 获取ip
            let ip, isChina, lang;
            lang = window.localStorage.getItem("lang");
            if (!lang) {
                try {
                    ip = await this.getIP();
                    isChina = await this.isChineseIP(ip);
                    this.$i18n.locale = isChina ? "CHN" : "ENG";
                } catch (error) {
                    this.$i18n.locale = "CHN";
                }
            } else {
                this.$i18n.locale = lang ?? "ENG";
            }
            
           

            window.localStorage.setItem("lang", this.$i18n.locale);

            setTimeout(() => {
                if(lang==='CHN'){
                    window.location.hash='zh'
                }else{
                    window.location.hash='en'
                }
            }, 1000);
        },
        formatJson(data, name) {
            let json;
            try {
                json = JSON.parse(data[name]);
            } catch (error) {
                json = {};
            }
            return json;
        },
        async fetchData() {
            let res = await getInfo();
            let data = res[0];
            data.config = this.formatJson(data, "config");
            this.$store.commit("SET_INFO", data);
        },
        async getArtists() {
            let res = await getArtists();
            this.$store.commit("SET_ARTISTS", res);
        },
        async getCategories() {
            let res = await getCategories();
            this.$store.commit("SET_CATEGORIES", res);
        },
        async isChineseIP(ip) {
            const response = await fetch(`http://ip-api.com/json/${ip}`);
            const data = await response.json();
            // return data.country === 'China';
            return ["China", "Taiwan", "Hong Kong", "Macao"].includes(
                data.country
            );
        },
        async getIP() {
            return new Promise((resolve) => {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", "https://api.ipify.org?format=json", true);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        var response = JSON.parse(xhr.responseText);
                        var ip = response.ip;
                        resolve(ip);
                    }
                };
                xhr.send();
            });
        },
        loadRouter() {
            let routers = [
                {
                    path: "",
                    redirect: "/home",
                },
                {
                    path: "/home",
                    name: "home",
                },
                {
                    path: "/artist",
                    name: "artist",
                },
                {
                    path: "/news",
                    name: "news",
                },
                {
                    path: "/news-detail/:id",
                    name: "news-detail",
                },
                {
                    path: "/projects",
                    name: "perform",
                },
                {
                    path: "/projects-detail/:id",
                    name: "perform-detail",
                },
                {
                    path: "/contact",
                    name: "contact",
                },
                {
                    path: "/about",
                    name: "about",
                },
            ].filter((item) => {
                return item.name!= this.$route.name;
            });

            setTimeout(() => {
                routers.forEach(item=>{
                    // let url=`/pages/${item.name}/index.vue`
                    // item.component=import(url)

                    let name=item.name
                    try {
                        item.component=import(`@/pages/${name}/index.vue`)

                    } catch (error) {
                        console.log(`import${name}路由失败`)
                    }
                })
            }, 2000);

            //获取当前加载的路由
            // console.log(this.$router.addRoute);
        },
    },
    mounted() {
        this.init();
        this.loadRouter();
    },
    created() {
        this.fetchData();
        this.getArtists();
        this.getCategories();
    },
};
</script>

<style lang="less">
@import "~@/assets/style/reset.css";
@import "~@/assets/font/font_icon/iconfont.css";

@font-face {
    font-family: ONEAN_MEDIUM;
    src: url("~@/assets/font/ONEAN-Medium.otf");
}

@font-face {
    font-family: ONEAN_REGULAR;
    src: url("~@/assets/font/ONEAN-Regular.otf");
}

@font-face {
    font-family: ONEAN_BLOD;
    src: url("~@/assets/font/ONEAN-Bold.otf");
}

@font-face {
    font-family: ONEAN_BLOD;
    src: url("~@/assets/font/ONEAN-Bold.otf");
}

html,
body,
#app {
    height: 100%;
    width: 100%;
    position: relative;
    // font-family: ONEAN_MEDIUM;
}

#app {
    position: relative;
    -webkit-font-smoothing: subpixel-antialiased;
    overflow-x: hidden;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s;
    opacity: 1;
}

.fade-enter,
.fade-leave-to {
    position: absolute !important;
    right: 0;
    top: 0;
    left: 0;
    // width: 100vw;
    opacity: 0;
}

.fade-enter-active,
.fade-enter-to {
    position: absolute !important;
    right: 0;
    top: 0;
    left: 0;
    // width: 100vw;
    // height: 100%;
    z-index: 1;
}

.image-slot {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
</style>
