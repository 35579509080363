import axios from 'axios'
import { Message } from 'element-ui'
const service = axios.create({
//   baseURL: process.env.NODE_ENV === 'development' ? '/':process.env.VUE_APP_BASE_API,
  baseURL: '/',
  timeout: 25000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
 
  response => {
    if (response.status == 200) {
     return response.data
    } else {
      return response
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
