import request from '@/utils/request'


export function getInfo() {
    return request({
      url: `/api/systems`,
      method: 'get',
    })
  }

  export function getNews(id) {
    return request({
      url: id===undefined?`/api/news`:`/api/news/${id}`,
      method: 'get',
    })
  }
  export function getContact() {
    return request({
      url: `/api/contacts`,
      method: 'get',
    })
  }
  export function getPerforms(id) {
    return request({
      url: id===undefined?`/api/performs`:`/api/performs/${id}`,
      method: 'get',
    })
  }
  export function getArtists(id) {
    return request({
      //url: `/api/artists`,
      url: id===undefined?`/api/artists`:`/api/artists/${id}`,
      method: 'get',
    })
  }
  export function getCategories() {
    return request({
      url: `/api/categories`,
      method: 'get',
    })
  }

  export function fetchBiliBiliVideoInfo(id) {
    return request({
        url: `/bilibili/x/web-interface/view?bvid=${id}`,
        method: 'get',
    })
}